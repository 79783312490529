// extracted by mini-css-extract-plugin
export var card = "StyledCard-module--card--O2FhP";
export var cardB = "StyledCard-module--cardB--1xIm_";
export var cardInner = "StyledCard-module--cardInner--3yjkL";
export var imageWrapper = "StyledCard-module--imageWrapper--2ToF8";
export var content = "StyledCard-module--content--39ZQG";
export var contentLabel = "StyledCard-module--content-label--1dITz";
export var contentHasLabel = "StyledCard-module--content-has-label--1FMi2";
export var cardE = "StyledCard-module--cardE--1XcZC";
export var cardC = "StyledCard-module--cardC--1tRnx";
export var videoSmall = "StyledCard-module--videoSmall--2Es49";
export var videoLarge = "StyledCard-module--videoLarge--fcydq";
export var upNextWrapper = "StyledCard-module--upNextWrapper--6Y8he";
export var cardCategory = "StyledCard-module--cardCategory--2yyLq";
export var cardDetails = "StyledCard-module--card-details--1-wPi";
export var featured = "StyledCard-module--featured--X1-DS";
export var contentHasLabelLarge = "StyledCard-module--content-has-label-large--3YXKm";
export var contentHasLabelFeatured = "StyledCard-module--content-has-label-featured--2zXSE";
export var searchContent = "StyledCard-module--search-content--3zyrU";
export var featuredArticleLink = "StyledCard-module--featured-article-link--3EY__";
export var featuredArticleHeader = "StyledCard-module--featured-article-header--vnCAy";
export var featuredContentLabel = "StyledCard-module--featured-content-label--2e9v2";