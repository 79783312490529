import React from 'react'
import Link from '../Link'

const ServiceLabel = ({ service, separator = '—' }) => {
	if (!service) {
		return (<>News {separator} </>)
	}

	const category = { name: service[0]?.title, uri: service[0]?.uri }

	if (service[0]?.serviceTypes?.nodes[0]?.slug === 'sector') {
		category.uri = service[0].uri.replace('/service/', '/sector/')
	}

	return (
		<>
			{category.name} {separator}
		</>
	)
}

export default ServiceLabel
