import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'
import {
	styledImage,
	styledImageRound,
	styledImageCircle,
} from './StyledImage.module.scss'

// with GatsbyImage, you can only pass one className
// clickableResource is not image src, its where user goes when clicking on image
const StyledImage = ({ variant, imageData, alt, ...props }) => {
	let image = null;
	if (typeof imageData === 'object' && imageData !== null) {
		image = getImage(imageData)
	}

	const placeholderImage = "../../../images/logo-placeholder.png"
	
  // heights must be hard coded here or local variables only
	let variantLayout = variantType => {
		switch (variantType) {
			case 'circle':
				return (
					<StaticImage
						width={69}
						height={69}
						className={styledImageCircle}
						src="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
					/>
				)
			case 'small':
				return image ? (
					<GatsbyImage
						alt={alt}
						image={image}
						className={styledImageRound}
					/>
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						width={168}
						height={168}
						className={styledImageRound}
					/>
				)
			case 'small-tall':
				return (
					<StaticImage
						width={168}
						height={230}
						className={styledImage}
						src="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
					/>
				)
			case 'medium':
				return image ? (
					<GatsbyImage alt={alt} image={image} className={styledImageRound} />
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						className={styledImageRound}
						width={550}
						aspectRatio={1.59}
					/>
				)
			case 'medium-square':
				return image ? (
					<GatsbyImage alt={alt} image={image} className={styledImageRound} />
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						aspectRatio={4 / 4}
						width={267}
						height={267}
						className={styledImageRound}
					/>
				)
			case 'medium-large-square':
				return image ? (
					<GatsbyImage alt={alt} image={image} className={styledImageRound} />
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						aspectRatio={4 / 4}
						width={306}
						className={styledImageRound}
					/>
				)
			case 'large-square':
				return image ? (
					<GatsbyImage
						alt={alt}
						image={image}
						className={styledImageRound}
					/>
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						aspectRatio={4 / 4}
						width={465}
						height={465}
						className={styledImageRound}
					/>
				)

			case 'medium-tall':
				return image ? (
					<GatsbyImage
						alt={alt}
						image={image}
						className={styledImageRound}
					/>
				) : (
					<StaticImage
						layout="fullWidth"
						aspectRatio={0.75}
						className={styledImageRound}
						src={placeholderImage}
						alt={alt}
					/>
				)
			case 'large':
				return image ? (
					<GatsbyImage
						alt={alt}
						image={image}
						className={styledImageRound}
					/>
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						layout="fullWidth"
						className={styledImageRound}
					/>
				)
			case 'xlarge':
				return image ? (
					<GatsbyImage
						alt={alt}
						image={image}
						className={styledImageRound}
					/>
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						width={693}
						height={453}
						className={styledImageRound}
					/>
				)

			default:
				return image ? (
					<GatsbyImage
						alt={alt}
						image={image}
						className={styledImageRound}
					/>
				) : (
					<StaticImage
						src={placeholderImage}
						alt={alt}
						className={styledImageRound}
						width={550}
						aspectRatio={1}
					/>
				)
		}
	}
	return <>{variantLayout(variant)}</>
}

export default StyledImage

