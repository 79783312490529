import StyledIcon from '../Common/StyledIcon'
import React from 'react'
import {
	play,
	small,
	medium,
	large,
	alignBottomLeft,
	alignNone,
	bgColorLight,
	bgColorTransparent,
} from './Play.module.scss'

const Play = ({ size, align, bgColor, ...props }) => {
	const playClass = [play]

	switch (size) {
		case 'small':
			playClass.push(small)
			break
		case 'medium':
			playClass.push(medium)
			break
		default:
			playClass.push(large)
	}

	switch (align) {
		case 'bottom-left':
			playClass.push(alignBottomLeft)
			break
		case 'none':
			playClass.push(alignNone)
			break
	}

	switch (bgColor) {
		case 'light':
			playClass.push(bgColorLight)
			break
		case 'transparent':
			playClass.push(bgColorTransparent)
			break
	}

	return (
		<>
			<div className={playClass.join(' ')}>
				<StyledIcon
					name="play"
					size={size === 'small' ?? 'medium'}
					{...props}
				/>
			</div>
		</>
	)
}

export default Play
