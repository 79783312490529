import React from 'react'
import StyledLabel from '../StyledLabel'
import StyledImage from '../StyledImage'
import Link from '../../Link'
import Play from '../../Play'
import clsx from "clsx";
import {
	card,
	cardInner,
	cardB,
	cardC,
	cardE,
	content,
	contentHasLabel,
	contentHasLabelLarge,
	contentHasLabelFeatured,
	contentLabel,
	cardDetails,
	imageWrapper,
	featured,
	featuredArticleLink,
	featuredArticleHeader,
	featuredContentLabel,
	videoSmall,
	videoLarge,
	upNextWrapper,
	cardCategory,
} from './StyledCard.module.scss'
import ServiceLabel from '../../ServiceLabel'

const StyledCard = ({
	iconSize='small',
	service,
	title,
	label,
	labelId,
	labelPosRelative = true,
	text,
	href,
	eventDetails,
	double,
	headingSize = 'title--xs',
	image,
	imageSize,
	time,
	play,
	isFeatured,
	upNext,
	variation,
	sectionBg,
	showImage = true,
	...props
}) => {
	let cardClass = [card]
	let playAlignment = 'bottom-left'
	let playSize = 'small'
	let bgColor = 'transparent'

	// load variant class
	switch (variation) {
		case 'CardB':
			cardClass.push(cardB)
			break
		case 'CardC':
			cardClass.push(cardC)
		break
		case 'CardE':
			cardClass.push(cardE)
			break
		case 'VideoSmall':
			cardClass.push(videoSmall)
			break
		case 'VideoLarge':
			cardClass.push(videoLarge)
			playAlignment = 'center'
			playSize = 'large'
			break
	}

	if (labelPosRelative) {
		cardClass.push('position-relative h-100')
	}

	return (
		<article className={cardClass.join(' ')}>
			<div className={cardInner}>
				{showImage && (
					<div className={imageWrapper}>
						<Link to={href} className={ isFeatured ? featuredArticleLink : ''} state={{path: props?.backLink}}>
							<StyledImage variant={imageSize} imageData={image} alt={title} />
							{play && (
								<Play bgColor={bgColor} size={playSize} align={playAlignment} />
							)}

							{isFeatured && <span className={featured}>Featured</span>}
						</Link>
					</div>
				)}

				<div className={clsx(content, label ? contentHasLabel : '', label && iconSize !== 'small' ? contentHasLabelLarge : '', isFeatured ? contentHasLabelFeatured : '')}>
					<div className={'article-meta ' + cardCategory}>
						<ServiceLabel service={service} separator={false} />
					</div>

					{title && (
						<h3 className={clsx(headingSize, isFeatured ? featuredArticleHeader : '')}>
							<Link to={href} state={{path: props?.backLink}}>{title}</Link>
						</h3>
					)}

					{eventDetails && (
						<div className={'article-light ' + cardDetails}>
							{eventDetails.date}
							{eventDetails.location}
						</div>
					)}

					{label && (
					<div className={clsx(contentLabel, isFeatured ? featuredContentLabel : '')}>
						<StyledLabel round variant={labelId} label={label} size={iconSize} />
					</div>
					)}

					{time && (
						<StyledLabel
							iconColor="light"
							variant="time"
							size="small"
							time={time}
						/>
					)}

					{upNext && (
						<div className={upNextWrapper}>
							<div className="article-meta">{upNext.title}</div>
							{upNext.items.map((next, index) => {
								return <VideoUpNext {...next} key={`up-next-${index}`} />
							})}
						</div>
					)}
				</div>
				{/* /.content */}
			</div>
		</article>
	)
}

const VideoUpNext = ({ href, title, image, time }) => {
	return (
		<StyledCard
			variation="VideoSmall"
			href={href}
			title={title}
			image={image}
			eventDetails={null}
			imageSize="medium"
			time={time}
		/>
	)
}

export default StyledCard
